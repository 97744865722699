<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('invoice-adress-list')"
        @delete="helperDeleteItem(`/client/invoiceaddresses/${$route.params.invoiceid}`, null, 'invoice-adress-list')"
        @save="updateItem"
        @refresh="getItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
        :title="this.currentItem.title"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-col cols="12">
        <b-form @submit.stop.prevent="handleSubmit(updateItem)" @reset.prevent="resetForm">
          <b-card>
            <b-row>
              <b-col cols="6">
                <b-form-group :label="$t('Title')">
                  <validation-provider #default="{errors}" :name="$t('Title')" rules="required">
                    <b-form-input v-model="currentItem.title" :placeholder="$t('Title')"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('title')" class="text-danger">{{
                        $t(validation.title[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group :label="$t('Firm')">
                  <validation-provider #default="{errors}" :name="$t('Firm')" rules="required">
                    <b-form-input v-model="currentItem.firm" :placeholder="$t('Firm')"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('firm')" class="text-danger">{{
                        $t(validation.firm[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group :label="$t('Firstname')">
                  <validation-provider #default="{errors}" :name="$t('Firstname')" rules="required">
                    <b-form-input v-model="currentItem.firstname" :placeholder="$t('Firstname')"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('firstname')" class="text-danger">{{
                        $t(validation.firstname[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Name')">
                  <validation-provider #default="{errors}" :name="$t('Name')" rules="required">
                    <b-form-input v-model="currentItem.name" :placeholder="$t('Name')"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                        $t(validation.name[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group :label="$t('Street')">
                  <validation-provider #default="{errors}" :name="$t('Street')" rules="required">
                    <b-form-input v-model="currentItem.street" :placeholder="$t('Street')"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('street')" class="text-danger">{{
                        $t(validation.street[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Addressline')">
                  <validation-provider #default="{errors}" :name="$t('Addressline')" rules="required">
                    <b-form-input v-model="currentItem.addressline_2" :placeholder="$t('Addressline')"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('addressline')" class="text-danger">{{
                        $t(validation.addressline[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group :label="$t('City')">
                  <validation-provider #default="{errors}" :name="$t('City')" rules="required">
                    <b-form-input v-model="currentItem.city" :placeholder="$t('City')"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('city')" class="text-danger">{{
                        $t(validation.city[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Postal')">
                  <validation-provider #default="{errors}" :name="$t('Postal')" rules="required">
                    <b-form-input v-model="currentItem.postal" :placeholder="$t('Postal')" type="number"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('postal')" class="text-danger">{{
                        $t(validation.postal[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col cols="6">
                <b-form-group :label="$t('Country')">
                  <validation-provider #default="{errors}" :name="$t('Country')" rules="required">
                    <b-form-input v-model="currentItem.country" :placeholder="$t('Country')"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                    <small v-if="Object.keys(validation).includes('country')" class="text-danger">{{
                        $t(validation.country[0])
                      }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>


            <div class="d-flex mt-1">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit"
                        @click.prevent="updateItem"
              >
                {{ $t('Update Invoice Adress') }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </validation-observer>

  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { alphaNum, email, required } from '@validations'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    required,
    alphaNum,
    email,
    BFormCheckbox,
    Ripple,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {

      currentItem: {
        firm: '',
        title: '',
        firstname: '',
        name: '',
        street: '',
        addressline_2: '',
        postal: '',
        city: '',
        country: '',

      },
      loaded: true,
      validation: {},
      validate: false,

    }
  },

  methods: {
    getItem() {
      this.helperGetItem(`/client/invoiceaddresses/${this.$route.params.invoiceid}`)
    },

    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(`/client/invoiceaddresses/${this.$route.params.invoiceid}`, this.currentItem)
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

  },
  mounted() {
    this.$http.get(`/client/invoiceaddresses/${this.$route.params.invoiceid}`)
        .then(response => {
          this.currentItem = response.data
        })
        .catch(error => {
        })
  },
}
</script>

<style>
</style>
